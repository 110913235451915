'use strict';

angular.module('game').
factory('videoServices', [
  'angularServices',
  'globalService',
  'apiCall',
  'keylogger',
  'config',
  'socketService',
  'playerService',
  'gameSettings',
  'logService',
  function (angularServices, globalService, apiCall, keylogger, config, socketService, playerService, gameSettings, logService) {

    var $interval = angularServices.$interval,
      $timeout = angularServices.$timeout,
      gameConstants = globalService.gameConstants,
      mediaURL = gameConstants.mediaURL,
      contentURL = gameConstants.contentURL,
      i18nService = globalService.i18nService;

    // Steps to be jumped between, when using the keylogger
    var steps, keyNextStep, desiredBufferReached = false;

    var videoServices = {
      animating: false,

      /* SHOW EMAIL SCREEN AND IM READY BUTTON */
      interactionByEmail: function () {
        angular.element("#emailScreen").show();
        angular.element("#readyButton").show();
      },

      /* SHOW CORRECT ANSWER POINTS ANIMATION */
      floatText: function (value, total) {

        if (value > 0) {
          var $points = angular.element('#character-menu .userStatus .points');
          var $upperDiv = angular.element("#floatingText");

          var upperDivOffset = $upperDiv.offset();
          var pointsOffset = $points.offset();

          var top = pointsOffset.top - upperDivOffset.top + $points.height() + $upperDiv.position().top;
          var left = pointsOffset.left - upperDivOffset.left + $upperDiv.position().left;

          // Set on top of the points container
          $upperDiv.css({
            'top': top,
            'left': left,
            'opacity': 1,
          });

          i18nService.getLocale().then(function (locale) {
            $upperDiv.html("+ " + value + " " + locale['front.game.points.unit.plural']);

            angular.element("#floatingText").animate({
              top: top - 100,
              opacity: 0
            }, 3000);

            $timeout(function () {
              $upperDiv
                .css("opacity", "0")
                .html("");

            }, 3500);
          });
        }

      },
      timeout: "",

      /**
       * Returns the status of the challenge played
       * @returns Promise
       */
      getDecalogueStatus: function () {
        return apiCall.get('api/1/decalogue/status');
      },

      /**
       * Returns the challenge information
       * @param challengeId
       * @returns Promise
       */
      getChallengeData: function (challengeId) {
        return apiCall.get('api/1/challenge/' + challengeId);
      },

      /**
       * Indicates the start of countdown until the user choose the option
       * @param requestData
       * @returns {*}
       */
      postChallengeStartAnswer: function (requestData) {
        apiCall.post('api/1/challenge/start', requestData)
          .then(function () {
            logService.debug('Successfully start the challenge step answer');

          }).catch(function () {
            logService.error('Error starting the challenge step answer');
        });
      },

      /**
       * Indicates the option chosen by the user
       * @param requestData
       * @returns {*}
       */
      postChallengeAnswer: function (requestData) {
        return apiCall.post('api/1/challenge', requestData);
      },

      /**
       * Registers the steps that the keylogger allows to jump between
       * @param theSteps
       */
      registerSteps: function (theSteps) {
        steps = theSteps;
      },

      /**
       * Register the next step the keylogger will allow to jump to
       *
       * @param newKeyNextStep
       */
      registerNext: function (newKeyNextStep) {
        keyNextStep = newKeyNextStep;
      },

      /**
       * Activates the Keylogger only for development to jump between
       * questions during the video play.
       * It also allows to publish a dummy event in the streamline.
       */
      activateDevKeyLogger: function ($scope, videoPlayer) {

        if (config.devMode) {
          keylogger.log('dev', function () {
            logService.debug('Dev mode on');

            keylogger.removeLog('dev');

            $scope.highlightMax = true;

            keylogger.log('next', function () {
              logService.debug('Dev mode : Next');
              if (keyNextStep && steps[keyNextStep]) {
                videoPlayer.seek(steps[keyNextStep].questionResource.beginSeconds);
              }
            });

            keylogger.log('event', function () {
              logService.debug('Dev mode : event');
              var data = {
                avatarI18n: '/images/avatars/user.jpg',
                name1I18n: 'Pepe bate su propio record!',
                timestamp: new Date().getTime()
              };
              socketService.addStream([data]);
            });

            keylogger.log('win', function() {
              logService.debug('Dev mode : win');
              videoServices.showDecaloguePoint('Excelente respuesta vamos a por muchas mas! Sigue así!');
            });

            keylogger.log('points', function() {
              logService.debug('Dev mode : points');
              videoServices.floatText('10');
            });
          });

          $scope.$on("$destroy", function () {
            keylogger.stop();
          });
        }
      },

      /* SHOW CORRECT ANSWER MESSAGE ANIMATION */
      showDecaloguePoint: function (value, showAndHide) {

        var self = this;
        self.animating = true;
        var div = angular.element("#decalogueAnswer");
        div.html(value);
        div.addClass('animate');

        if (Utils.isIE9()) {
          div.css("bottom", "-40px").css("opacity", 0);
          div.animate({
            bottom: "24px",
            opacity: 1
          }, 2500);
        }

        $timeout(function () {
          div.html("").removeClass('animate');
          self.animating = false;
        }, 4000);

      },

      showLastPoint: function (value) {
        var self = this;
        if (!self.animating) {
          angular.element(".finishOptions").show();
        } else
          $timeout(function () {
            self.showLastPoint(value);
          }, 4000);
      },

      /* blink of lifes when fail*/
      lifeBlink: function () {
        var counter = 0,
            visible = true,
            life = angular.element(".lifes-wrapper").find(".lifes:not(.losed):first");

        if (life.length > 0) {
          var heart = life.find('img');
          $interval(function () {
            if (visible) {
              heart.css("display", "none");
              visible = false;
            } else {
              heart.css("display", "inline");
              visible = true;
            }
            counter = counter + 1;
          }, 150, 15);
          life.addClass("losed");
        }
      },

      /**
       * Returns a default onReady event
       * @param options
       * @returns {Function}
       * @private
       */
      _getDefaultOnReady: function (options) {
        var player = options.player;
        return function () {
          var playerElement = player.getElement();
          var videoWrapper = playerElement.closest('.b-video-wrapper');
          if (!gameSettings.video.autostart || Utils.isMobileOrTablet() || Utils.isSafari() || Utils.isChrome() || Utils.isFirefox()) {
            var playBtn = playerElement.find('+ .btn-play');

            playBtn.click(function(e) {
              videoWrapper.addClass('b-video-wrapper--playing');
              player.setVolume(options.volume);
              player.play();
              playBtn.hide();
              options.canPlay = true;
              e.stopPropagation();
            });
          } else {
            videoWrapper.addClass('b-video-wrapper--playing');
            player.setVolume(options.volume);
          }
        };
      },

      /**
       * Returns a default onPause event
       * @param options
       * @returns {Function}
       * @private
       */
      _getDefaultOnPause: function(options) {
        var player = options.player;
        var preloadBuffer = options.preloadBuffer;
        return function() {
          // if ((!preloadBuffer || desiredBufferReached) && options.waitingTimeToPlayFinished == 0) {
          //   player.play();
          // }
          angular.element(".videoButtonsContainer").addClass('fixed');
          if (Utils.isMobileOrTablet()) {
            angular.element(".playPauseIndicator").addClass('fixed');
          }
        };
      },

      /**
       * Returns a default onPlay event
       * @param options
       * @returns {Function}
       * @private
       */
      _getDefaultOnPlay: function(options) {
        var player = options.player;
        var preloadBuffer = options.preloadBuffer;
        return function () {
          angular.element(".videoButtonsContainer").removeClass('fixed');
          angular.element(".playPauseIndicator").removeClass('fixed');
          var waitingTimeToPlayFinished = options.waitingTimeToPlayFinished;
          if ((!preloadBuffer || desiredBufferReached) && waitingTimeToPlayFinished != 0 && 0 === parseInt(player.getPosition())) {
            // player.pause();
          } else {
            if(options.waitingTimeToPlayFinished == 0) {
              angular.element("#videoWrapper").css('background', "rgba(0, 0, 0, 0.5)");
              if (!preloadBuffer) {
                angular.element("#waitScreen").css('display', 'none');
              }
            }
          }
        };
      },

      _getDefaultOnTime: function(options) {
        var player = options.player;
        var preloadBuffer = options.preloadBuffer;
        var ending = options.ending;

        return function (e) {
          if (preloadBuffer && e.position >= ending && !desiredBufferReached) {
            options.waitingTimeToPlayFinished = 0;
            player.setVolume(0);
            desiredBufferReached = true;
          }
        }
      },

      /**
       * Returns a default onBufferChange event
       * @param options
       * @returns {Function}
       * @private
       */
      _getDefaultOnBufferChange: function(options) {
        var player = options.player;
        var preloadBuffer = options.preloadBuffer;
        return function (e) {
          var bufferTimeout = $timeout(function () {
            if (lastBuffer != null) {

              var isIEAndHaveLoadedSomeVideo = (Utils.isIE() && player.getBuffer() > 5); // @todo 5 is arbitrary: minimum % to start playing in IE
              var hasTheVideoStopLoading = lastBuffer == player.getBuffer();

              var currentTime = new Date();
              var time = { hour: currentTime.getHours(), minute: currentTime.getUTCMinutes() };
              if (lastBuffer != 100 && (hasTheVideoStopLoading || isIEAndHaveLoadedSomeVideo)) {
                if (!preloadBuffer) {
                  options.waitingTimeToPlayFinished = 0;
                }

                if (options.canPlay) {
                  logService.debug('Buffer changed [timeout][' + time.hour + ':' + time.minute + ']: current buffer: ' + player.getBuffer() + ' - last buffer:' + lastBuffer);
                  player.play();
                }
              } else {
                logService.debug('Buffer changed [timeout][' + time.hour + ':' + time.minute + ']: not ready to play - current buffer: ' + player.getBuffer() + ' - last buffer:' + lastBuffer);
              }
            }
          }, 3000);
          var lastBuffer = e.bufferPercent;

          if (e.bufferPercent == 100 || !preloadBuffer) {
            // todo: fix preloading that starts video at background at incorrect moments
            //logService.debug('Buffer changed: Reached 100%');
            $timeout.cancel(bufferTimeout);
            options.waitingTimeToPlayFinished = 0;
            if (options.canPlay && player.isPlaying()) {
              player.play();
            }
          }
        }
      },


      /**
       * Handle video buttons container
       * @param videoElement
       * @param videoElement
       * @private
       */
      _videoButtonsContainerhandler: function(videoElement) {
        var videoButtonsContainer = angular.element(".videoButtonsContainer");

        $timeout(function () {
          videoButtonsContainer.hide();
        }, 2000);

        var videoButtonsContainerTimer = null;

        videoButtonsContainer.on('mousemove', function (event) {
          event.stopPropagation();
          $timeout.cancel(videoButtonsContainerTimer);
          videoButtonsContainer.show();
        });

        videoElement.on('click', function() {
          videoButtonsContainer.show();
          $timeout.cancel(videoButtonsContainerTimer);
          videoButtonsContainerTimer = $timeout(function () {
            videoButtonsContainer.hide();
          }, 2500);
        });

        videoElement.on('mousemove', function() {
          $timeout.cancel(videoButtonsContainerTimer);

          videoButtonsContainer.show();

          videoButtonsContainerTimer = $timeout(function () {
            videoButtonsContainer.hide();
          }, 2500);
        });

        videoElement.on('mouseout', function () {
          $timeout.cancel(videoButtonsContainerTimer);
          videoButtonsContainer.hide();
        });
      },

      /**
       * Handle player overlayed buttons based on game settings
       * @private
       */
      _attachEventHandlersToPlayer: function() {
        var videoElement = angular.element('.videoInteraction');
        // Handle video buttons
        this._videoButtonsContainerhandler(videoElement);

        // Prevent video contextual menu
        angular.element('.b-video-wrapper').on('contextmenu', function(e) {
          e.preventDefault();
        });
      },

      /**
       *
       * @param options
       * @returns {{volume: (*|number), waitingTimeToPlayFinished: number, file: *, autostart: (boolean|*), events: (*|{onBufferChange: *, onPause: *, onPlay: *, onReady: *})}}
       * @private
       */
      _getPlayerSetup: function(options) {
        var file = options.file;
        var player = options.player;
        var autoStart = options.canPlay;
        var preloadBuffer = options.preloadBuffer || false;
        var volume = (player.getVolume() || ((options.volume !== undefined) && options.volume))|| 100;
        var waitingTimeToPlayFinished = options.waitingTimeToPlayFinished || 0;
        var defaultEvents = {
          onReady: (options && options.events && options.events.onReady) || this._getDefaultOnReady(options),
          onPause: options && options.events && options.events.onPause || this._getDefaultOnPause(options),
          onPlay: (options && options.events && options.events.onPlay) || this._getDefaultOnPlay(options),
          onTime: (options && options.events && options.events.onTime) || this._getDefaultOnTime(options),
          onBufferChange: (options && options.events && options.events.onBufferChange) || this._getDefaultOnBufferChange(options)
        };
        var events = (options.events && Object.assign({}, options.events, defaultEvents)) || defaultEvents;
        return {
          file: file,
          volume: volume,
          events: events,
          autostart: autoStart,
          preloadBuffer: preloadBuffer,
          waitingTimeToPlayFinished: waitingTimeToPlayFinished,
          withCredentials: true
        };
      },

      /* INITIALIZE PLAYER*/
      initializePlayer: function (url, state, subtitlesLang) {
        var fileURL = mediaURL + url;
        state.file = fileURL;
        state.waitingTimeToPlayFinished = 1;
        state.canPlay = !Utils.isSafari() && !Utils.isChrome() && !Utils.isMobileOrTablet() && gameSettings.video.autostart;

        var player = playerService.create("element");
        state.player = player;

        var playerSetup = this._getPlayerSetup(state);

        // Attach subtitules to player setup
        if (gameSettings.video.subtitles.active) {
          playerSetup.tracks = [{
            file: mediaURL + url + "." + subtitlesLang + ".vtt",
            kind: "captions",
            "default": true
          }];
          playerSetup.captions = gameSettings.settings.videoPlayer.settings.captions;
          logService.debug('Video subtitles at ' + mediaURL + url + "." + subtitlesLang + '.vtt');
        }

        player.setup(playerSetup);

        // Deals with events related to player
        this._attachEventHandlersToPlayer();

        if (state.canPlay) {
          player.play();
        }

        return player;
      },

      /* SET OPTION CONTENTS AND SHOW OPTIONS IN ORDER TO RECEIVED OPTIONS */
      fadeChain: function (options_data, highlightMax) {

        var regularElement = angular.element('.regular');
        var badElement = angular.element('.bad');
        var activeElement = angular.element('.answer.active');

        if (regularElement != null)
          regularElement.removeClass("regular");

        if (badElement != null)
          badElement.removeClass("bad");

        if (activeElement != null)
          activeElement.removeClass("active");

        var options = options_data;
        var maxId = Math.max.apply(null, Utils.getKeys(options));

        Utils.shuffle(Utils.getKeys(options)).forEach(function (i) {
          var optionElement = options[i];
          var classes = ['answer', 'set'];

          if (highlightMax && i == maxId) {
            classes.push('highlight');
          }

          var answerContent = '<div class="skewWrapper">' + optionElement.name1I18n + '</div>';
          if (optionElement.name1I18n.indexOf("&img;") === 0) {
            // It is an image answer
            answerContent = angular.element('<img src="' + contentURL + "images/challenges/answers/" + optionElement.name1I18n.replace("&img;", "") + '" />');
            classes.push('image')
          }
          var $answer = angular.element('<div id="' + i + '" class="' + classes.join(' ') + '"></div>');
          $answer.html(answerContent);
          $answer.appendTo('.answers-wrapper');
        });

        angular.element(".answer.set").slideDown();
        angular.element(".answer").removeClass("set");
      },

      /**
       * Pauses the video
       */
      stopVideo: function (player, state) {
        state.waitingTimeToPlayFinished = player.getPosition() - 1;
        console.log("pausing video")
        player.pause();
      },
      resumeVideo: function (player, state) {
        if (state.canPlay) {
          console.log("resuming video")
          player.play();
          return true;
        }

      }
    };

    return videoServices;
  }
]);
